import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "link-topine-treepine-tree-exerciseslink"
    }}><Link to="/pine-tree" mdxType="Link">{`Pine Tree Exercises`}</Link></h2>
    <h2 {...{
      "id": "link-toformsformslink"
    }}><Link to="/forms" mdxType="Link">{`Forms`}</Link></h2>
    <h2 {...{
      "id": "link-toessential-principlesthe-ten-essentialslink"
    }}><Link to="/essential-principles" mdxType="Link">{`The Ten Essentials`}</Link></h2>
    <h2 {...{
      "id": "link-tolessonslessonslink"
    }}><Link to="/lessons" mdxType="Link">{`Lessons`}</Link></h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      